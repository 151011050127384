import React, { createRef, useRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, List, message, Modal, Popconfirm, Select, Table, Tabs, Tag, Tree, TreeSelect, Typography } from 'antd'
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { useReactToPrint } from 'react-to-print'
import locale from 'antd/es/locale/zh_CN'
import pickerlocale from 'antd/es/date-picker/locale/zh_CN'

import { uniAddRowSubmit, uniAxList, uniCurrentTime, uniDoPrint, uniModifyAxList, uniNumToCh, uniQuitModal, uniSplitDigit, uniCheckStatus, uniDivideKey, uniFilterItem, uniDeepArray, uniBatchConfirm, uniOpAlarm, uniValueToName } from '../../utils/uniFucs'
import { EditableCellProps } from '../../utils/beans'
import { axAffixExSubjectList, axAffixProofList, axAffixSubjectDetail, axCreateAffixProof, axProofMonthe, axProofMonthf, axProofNext, axProofPre, axProofUpdate, axProofdetail, createProof, createProofTemplate, deleteProof, deleteProofTemplate, axSubjectList, getCompanyList, getParentSubjects, getProofList, getProofList2, getProofNoList, getProofSubjects, getProofTemplates, getReviewTempList, singleSubjectRemainder, updateProof, updateProofBatch, updateProofTemplate, axSubjectBindList, axSubjectBindAffixdetails, unReviewProof, reviewProof } from '../../utils/api'
import recordvouchercss from '../../css/recordvoucher.module.less'
import formModal from '../css/formModal.module.less'
import ColumnGroup from 'antd/lib/table/ColumnGroup'
import Column from 'antd/lib/table/Column'
import { ColumnsType } from 'antd/lib/table'
import TextArea from 'antd/lib/input/TextArea'
import { borrowOrLend, leadBorrow, materialBuyReviewList, materialReviewStatus } from '../../utils/constant'
import { getLocalItem, getUserInfoKey, getUserName } from '../../utils/localstorage'
import { TemplateItem } from '../../components/TemplateItem'
import FormModal from '../../components/FormModal'
import { initProofItem } from '../../utils/constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces'
import TopSearch from '../../components/TopSearch'
import SuperTable from '../../components/SuperTable'
import FormDialog from '../../components/FormDialog'
import CpTprint from '../../components/CpTprint'


export default function RecordVoucher() {
const pathname = "/indexpage/recordvoucher"
const searchRef = createRef<FormInstance>()
const topformRef = createRef<FormInstance>()
const printref:any = useRef()
/* constant */
const borrowKeys = ['hundredMillion', 'tenMillion', 'million', 'hundredThousand', 'tenThousand', 'thousand', 'hundred', 'ten', 'yuan', 'jiao', 'fen']
const lendKeys = ['hundredMillionL', 'tenMillionL', 'millionL', 'hundredThousandL', 'tenThousandL', 'thousandL', 'hundredL', 'tenL', 'yuanL', 'jiaoL', 'fenL']
const initSubjectModalControl = {currentTab:1,subjectid1:null,subjectid2:null,subjectid3:null,subjectid4:null,subjectid5:null,subjectid6:null}
const initMainControal = {showAffixDetail:false,editSubjectIndex:null,showSelectFooter:false,editRowkey:null,inputnum:null}
const modalRef = createRef<FormInstance>()
const modifyData = {
  checkStatus:[{newKey:'borrowLendName', checkKey: 'borrowLend', sourceList:borrowOrLend, sourceCheckKey:'value', sourceValueKey:'name'}],
}

/* hooks */
const [forceUpdate, setForceUpdate] = useState(true);
const navigate = useNavigate()
let location = useLocation()
const {state} = useLocation()
const tableRef = createRef<any>()
const selectRef = createRef<any>()
const formModalRef = createRef<FormInstance>()
const [editingKey, setEditingKey] = useState('')
let [rowList, setRowList] = useState([{key:-1,subjectName:'-'}] as any)
const [blankrow, setBlankrow] = useState({} as any)
const [hoverIndex, setHoverIndex] = useState(-1)
const [editIndex, setEditIndex] = useState(-1)
const [editKey, setEditKey] = useState('')
const [isDefaultCell, setIsDefaultCell] = useState(true)
const [companyList, setCompanyList] = useState([])
const [accountantSubjectList, setAccountantSubjectList] = useState([])
const [lendColSpan, setLendColSpan] = useState([1,1])
const [borrowColSpan, setBorrowColSpan] = useState([1,1])
const [totalSummary, setTotalSummery] = useState({total:'', borrow:0, lend:0} as any)
const [templateList, setTemplateList] = useState([] as any)
// const [titleInfo, setTitleInfo] = useState({createDate:uniCurrentTime({})+" 00:00:00",billYmd:uniCurrentTime({}), payYmd:uniCurrentTime({}), createBy:getUserName(), id:getUserInfoKey('id'), code: '',approverName:'',cancelStatus:0,enclosureNum:0} as any)
const [titleInfo, setTitleInfo] = useState({createDate:uniCurrentTime({}), createBy:getUserName(), code: '',approveStatus:0,approveStatusStr:'',approverName:'',cancelStatus:0} as any)
const [templateModal, setTemplateModal] = useState(false)
const [templateSelectIndex, setTemplateSelectIndex] = useState(-1)
const [tableLoading, setTableLoading] = useState(false)
const [saveDisable, setSaveIdsable] = useState({isDisable:true})
const [addModal, setAddModal] = useState(false)
const [countKey, setCountKey] = useState({key:0})
const [navigateFlag, setNavigateFlag] = useState({value:false})
const [navigateEdit,setNavigateEdit] = useState({value:false})
const [modalLoading, setModalLoading] = useState(false)
const [loadFlag, setLoadFlag] = useState(false)
const [maxTemplate, setMaxTemplate] = useState({value:999})
const [searchNo,setSearchNo] = useState('')
const [tablist,setTablist] = useState({tab1:[],tab2:[],tab3:[],tab4:[],tab5:[],tab6:[]} as any)
const [currentRow, setCurrentRow] = useState({} as any)
const [subjectModal,setSubjectModal] = useState(false)
const [affixDetailListControl,setAffixDetailListControl] = useState({} as any)
const [initSubjectId,setInitSubjectId] = useState({} as any)
const [backRowList,setBackRowList] = useState([])
const [initAffixDetailListControl,setInitAffixDetailListControl] = useState({} as any)
const [saveLoading,setSaveLoading] = useState(false)
const [affixList,setAffixList] = useState([])
const [affixdetalList,setAffixDetailList] = useState([])
let [modalEndym, setModalEndym] = useState(moment().format('YYYY-MM'))
let [subjectbindList,setSubjectbindList] = useState([])
let [curbindKey,setCurbindKey] = useState('')
let [cursubjectItem, setCursubjectItem] = useState({} as any)
const [showModal, setShowModal] = useState(false)
const [formItems,setFormItems] = useState([] as FormItemObj[])
let [affixDetailInfo,setAffixDetailInfo] = useState({} as any)
let [editCell,setEditCell] = useState({r:-1,c:''})
let [searchProofkey, setSearchProofkey] = useState()
let [tmpLoading,setTmpLoading] = useState(false)
let [fromTmpl,setFromTmpl] = useState(false)
let [uptmpLoading,setUptmpLoading] = useState(false)
let [modalFlag,setModalFlag] = useState('')
let [modaltableLoading,setModaltableLoading] = useState(false)
let [mselectedRowKeys,setMselectedRowKeys] = useState([] as any)
let [mselectedRows,setMselectedRows] = useState([] as any)
let [curTmplate,setCurTmplate] = useState({} as any)
let [formInitials,setFormInitials] = useState({})
let [modelcompanyItem, setModelCompanyItem] = useState({} as any)
let [showPrintmodal,setShowPrintmodal] = useState(false)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '凭证号', prop:'filter_LIKES_name'}
]
//顶部操作
const topActions:ColumnAction[] = [
{label: navigateFlag.value ? '保存修改' : '新建', type:'primary',disabled: navigateFlag.value && titleInfo.approveStatus!==1,loading:saveLoading,action: () => saveProof()},
{label: [1,3].includes(titleInfo.approveStatus) ? '审核' : '反审核', type:'primary',show: navigateFlag.value && titleInfo.approveStatus!==0,action: () => {
  if ([1,3].includes(titleInfo.approveStatus)) {
    setModalFlag('review')
    setShowModal(true)
  } else uniOpAlarm(()=>uniAxList(dataUnConfirm()))
}},
{label: '重置', type:'primary',show:!navigateFlag.value,action: () => reset()},
{label: '选择模板', type:'primary',show:!navigateFlag.value,action: () => {
  if (curTmplate.id) setMselectedRowKeys([curTmplate.id])
  setModalFlag('choosetmp')
  setShowModal(true)
  uniAxList(dataTempList)
}},
{label: '保存为新模板', type:'primary',loading:tmpLoading,action: () => {
  setModalFlag('newtmp')
  setShowModal(true)
}},
{label: '修改模板', type:'primary',show:fromTmpl,loading:uptmpLoading,action: () => {
  setModalFlag('updatetmp')
  setFormInitials({name:curTmplate.name,code:curTmplate.code})
  setShowModal(true)
}},
{label: '打印', type:'primary',isDanger:true,action: () => {
  console.log('rowlist------',rowList)
  let finditem:any = companyList.find((item:any)=>item.id===titleInfo.companyId)
  setTitleInfo({...titleInfo,invoiceTitle:finditem.invoiceTitle})
  setShowPrintmodal(true)
}},
{label: '凭证搜索',show:navigateFlag.value,render:<>
<Button size="small" type="default" onClick={async () => uniAxList(dataProofList('monthf'))}>当月首张&lt;&lt;</Button>&nbsp;
<Button size="small" type="default" onClick={() => uniAxList(dataProofList('pre'))}>上一张&lt;</Button>&nbsp;
<Input size="small" readOnly style={{width:100}} value={titleInfo.code}/>&nbsp;
<Button size="small" type="default" onClick={() => uniAxList(dataProofList('next'))}>&gt;下一张</Button>&nbsp;
<Button size="small" type="default" onClick={() => uniAxList(dataProofList('monthe'))}>&gt;&gt;当月末张</Button>&nbsp;
</>},
]
//添加和修改中的modal项
const topformItems:FormItemObj[] = [
// {label: '记字号',prop: 'code',disabled:true},
{label: '创建日期',prop: 'createDate',type:'datePicker',disabled:true,marginLeft:40,change:(value:any)=>{
  setTitleInfo({...titleInfo,createDate:moment(value).format('YYYY-MM-DD')})
}},
{label: '所属公司',prop: 'companyId',marginLeft:40,type:'select',dataList:companyList,disabled:navigateFlag.value,change:(value:any,operation:any)=>{
  topformRef.current?.setFieldsValue({billYmd:null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===0) {
    setTitleInfo({...titleInfo,companyId:null,billYmd:null})
    return message.error('所选公司未初始化, 不能创建凭证')
  } else {
    uniAxList(dataSubjectList(value))
    setModalEndym(finditem.endYm)
    setModelCompanyItem(finditem)
    setTitleInfo(()=>({...titleInfo,billYmd:null,companyId:value,companyName:operation.children}))
  }
}},
{label: '账单日期',prop: 'billYmd',type:'datePicker',marginLeft:40,datepickerProps:{returnType:'string',disabledDate:(current)=>current<moment(modelcompanyItem.beginYm).startOf('month') || current>moment(modelcompanyItem.endYm).endOf('month')},change:(value:any)=>{
  setTitleInfo({...titleInfo,billYmd:moment(value).format('YYYY-MM-DD')})
}},
{label: '制单人',prop: 'createBy',disabled:true,marginLeft:40},
{label: '状态',prop: 'approveStatusStr',disabled:true,marginLeft:40},
{label: '审核人',prop: 'approverName',disabled:true,marginLeft:40},
]
//审批modal中的item
const reviewItems:FormItemObj[] = [
{label: '审核结果',prop: 'reviewStatus',type:'select',dataList:materialBuyReviewList},
{label: '备注',prop: 'remark',required:false}
]
//凭证table列项
const columns:ColumnItem[] = [
{title: '', key: 'operation', type:'operation',align:'center',fixed:false,width: 40,onCell: (record:any, index:any) => ({style: {width:50,height:60, padding: 0,boxSizing:'border-box'}}),
  render: (_: any, record: any, index:number) => {
    return (
      index === hoverIndex
      ? <div className={recordvouchercss.buttonwrap}>
        <PlusCircleOutlined className={recordvouchercss.adddel} onClick={() => {
          countKey.key+=20;
          let addRow = {...blankrow, key:countKey.key}
          rowList.splice(index+1,0,addRow)
          setRowList(()=>[...rowList])
        }}/>
        <MinusCircleOutlined className={recordvouchercss.adddel} onClick={() => onDel(index, record)}/>
      </div>
      : <div style={{width:49}}></div>
    )
  }
},
{title: '借贷',width: 70,align: 'center',key: 'borrowLend',
  onCell: (record:any, index:any) => ({style:{height:60,padding:0,display:'flex',justifyContent:'center',alignItems:'center'}}),
  custval:({row,value}:any)=>uniValueToName({list:leadBorrow,value}),
  render: (value:any,record:any)=><div style={{width:70}}>{uniValueToName({list:leadBorrow,value})}</div>
},
{title: '摘要',width: 200,align: 'center',key: 'summary', onCell: (record:any, index:any) => onRowcell(index, 'summary'),
  render: (summary:any, record:any, index:any) => {
      return index === rowList.length - 1
            ? <span>总计：{totalSummary.total}</span>
            : (editCell.r===index && editCell.c==='summary')
              ? <TextArea autoFocus defaultValue={summary} style={{ height: 50, width: 200, resize:'none' }} placeholder="输入摘要" onBlur={(event)=>onCellBlur(event, record, 'summary')}/>
              : <div style={{width:200}}>{summary}</div>
  }
},
{title: '会计科目',width: 240,key: 'subjectName', align: 'center',onCell: (record:any, index:any) => onRowcell(index, 'subjectName'),
  render: (subjectName:any, record:any, index:any) => {
    return (editCell.r===index && editCell.c==='subjectName')
    ? <>
    <TreeSelect
      autoFocus
      showSearch
      treeNodeFilterProp='title'
      fieldNames={{value: 'id'} as any}
      style={{ width: 240 }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="请选择会计科目"
      allowClear
      treeData={accountantSubjectList}
      defaultValue={record['subjectId']}
      onClick={()=>{
        if (record.affixdetail) setAffixDetailList(()=>record.affixdetail)
      }}
      onChange={(value, children:any) => {
        if (value) {
          if (record.subjectId !== value) record['subjectAccountingDetailId'] = null
          let parentItem:any
          let childItem:any = uniFilterItem(accountantSubjectList,'id',value)
          if (childItem && childItem.parentId !==null) parentItem = uniFilterItem(accountantSubjectList,'id',childItem.parentId)
          record['subjectNo'] = value ? children[0].split(' ')[0] : ''
          record['subjectId'] = value
          if (parentItem) record['subjectName'] = parentItem.name + '-' + childItem.name
          else record['subjectName'] = childItem ? childItem.name : ''
        } else {
          record['subjectNo'] = ''
          record['subjectId'] = ''
          record['subjectName'] = ''
          record['accountingTypeDetailProof'] = null
        }
      }}
      onBlur={()=>setEditCell({r:-1,c:''})}
    />
    </>
    : <div style={{width:240}}>{subjectName}</div>
  }
},
{title: '辅助核算',width: 300,align:'center',key: 'accountingDetailNoName',
  onCell: (record:any, index:any) => onRowcell(index, 'accountingDetailNoName'),
  render: (accountingDetailNoName:any, record:any, index:any) => {
    return (editCell.r===index && editCell.c==='accountingDetailNoName' && cursubjectItem?.subjectAccounting?.length > 0)
        ? <div style={{position:'absolute',zIndex:5,top:0,width:300,backgroundColor:'#fff',border:'1px solid skyblue'}} onClick={(e)=>e.stopPropagation()}>
            <div style={{padding:4,display:'flex'}}>
              <span style={{marginRight:10}}>详情组合</span>
              <Select
                value={curbindKey}
                style={{ width:220 }}
                size="small"
                allowClear
                placeholder="请选择"
                showSearch
                filterOption={(inputkey:any,option:any)=>(option?.children ?? '').includes(inputkey)}
                onChange={(value:any)=>setCurbindKey(value)}
              > 
                {subjectbindList.map((listitem:any) => <Select.Option key={listitem['id']} value={listitem['id']}>{listitem['noName']}</Select.Option>)}
              </Select>
            </div>
            <Button size="small" onClick={()=>{
              setAffixDetailListControl(()=>initAffixDetailListControl)
              setEditCell({r:-1,c:''})
              //情况添加modal中的表项
              setAffixDetailInfo({})
              setFormItems([])
            }}>取消</Button>
            <Button size="small" type="primary" danger onClick={()=>{
              setModalFlag('accounting')
              setShowModal(true)
              cursubjectItem.subjectAccounting.forEach((item:any)=>{
                uniAxList(dataAffixDetailList(item.accountingId,titleInfo.companyId))
              })
            }}>添加</Button>
            <Button size="small" type='primary' onClick={()=>{
              let finditem:any = subjectbindList.find((item:any)=>item.id===curbindKey)
              record.subjectAccountingDetailId = curbindKey
              record.accountingDetailNoName = finditem.noName
              setRowList(() => [...rowList])
              setSubjectbindList(()=>[])
              setEditCell({r:-1,c:''})
              //情况添加modal中的表项
              setAffixDetailInfo({})
              setFormItems([])
            }}>确定</Button>
          </div>
        : <div style={{width:300}}>{record.accountingDetailNoName}</div>
  }
},
{title: '借方金额', key:'borrowPrice',width: 160,onCell: (record:any, index:any) => onRowcell(index, 'borrowPrice'),render: (value:any, record:any, index:any) => {
    return index === rowList.length - 1
      ? <span>{totalSummary.borrow ? totalSummary.borrow.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</span>
      :(editCell.r===index && editCell.c==='borrowPrice')
          ? <Input autoFocus value={record.borrowPrice || null} style={{ height: 60, width:'100%' }} placeholder="输入金额" onBlur={(event)=>onCashCellBlur(Number(event.target.value), record, index, 'borrowPrice')} onChange={(e)=>{
            let value:any = e.target.value
            let originprice = record.borrowPrice || 0
            if (value === '=' && totalSummary.lend > totalSummary.borrow) {
              console.log('if-------',e.target.value)
              record.borrowPrice = Number((totalSummary.lend-totalSummary.borrow).toFixed(2))
              totalSummary.borrow = totalSummary.lend
            } else if (value === '-') {
              if (!record.borrowPrice || record.borrowPrice==='-') record.borrowPrice = value
            } else if (value && !isNaN(value.replace('.','0'))) {
              console.log('else if-------',e.target.value)
              totalSummary.borrow += (Number(value) - (Number(record.borrowPrice) || 0))
              record.borrowPrice = value
            } else {
              console.log('else-------',e.target.value)
              totalSummary.borrow -= originprice
              record.borrowPrice = null
            }
            setRowList(()=>[...rowList])
          }}/>
          : <span>{value?Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span>
}},
{title: '贷方金额', key:'lendPrice',width: 160,onCell: (record:any, index:any) => onRowcell(index, 'lendPrice'),render: (value:any, record:any, index:any) => {
  return index === rowList.length - 1 ? <span>{totalSummary.lend ? totalSummary.lend.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</span>
    :(editCell.r===index && editCell.c==='lendPrice')
    ? <Input autoFocus value={record.lendPrice || null} style={{ height: 60, width:'100%' }} placeholder="输入金额" onBlur={(event)=>onCashCellBlur(Number(event.target.value), record, index, 'lendPrice')} onChange={(e)=>{
      let value:any = e.target.value
      let originprice = record.lendPrice || 0
      if (value === '=' && totalSummary.borrow>totalSummary.lend) {
        record.lendPrice = Number((totalSummary.borrow-totalSummary.lend).toFixed(2))
        totalSummary.lend = totalSummary.borrow
      } else if (value === '-') {
        if (!record.lendPrice || record.lendPrice==='-') record.lendPrice = value
      }
      else if (value && !isNaN(value.replace('.','0'))) {
        totalSummary.lend += (Number(value) - (Number(record.lendPrice) || 0))
        record.lendPrice = value
      } else {
        totalSummary.lend -= originprice
        record.lendPrice = null
      }
      setRowList(()=>[...rowList])
    }}/>
    : <span>{value?Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span>
}}
]
//凭证table列项
const tmpcolumns:ColumnItem[] = [
{title: '名称', key:'name'},
{title: '编码', key:'code'},
{title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDeltmprow(row.id)))}
]}}
]
//oncell中的属性
const onRowcell:any = (index:any, key:string) => {
  if (index !== rowList.length - 1) {
    return {
      style: {padding: 0,boxSizing:'border-box'},
      onClick: () => {
        if (key==='borrowPrice') {
          let temp = rowList[index].lendPrice || 0
          totalSummary.lend -= temp
          rowList[index].borrowLend = 1
          rowList[index].lendPrice = null
        } else if (key==='lendPrice') {
          let temp = rowList[index].borrowPrice || 0
          totalSummary.borrow -= temp
          rowList[index].borrowLend = 2
          rowList[index].borrowPrice = null
        }
        setEditCell({r:index,c:key})
        if (key === 'accountingDetailNoName') {
          setCurbindKey(rowList[index].subjectAccountingDetailId)
          if (rowList[index].subjectId && titleInfo.companyId) {
            let finditem:any = accountantSubjectList.find((item:any)=>item.id===rowList[index].subjectId)
            setCursubjectItem(finditem)
            if (finditem.subjectAccounting.length <= 0) return message.warning('该科目没有绑定辅助核算')
            // setAffixList(()=>finditem.subjectAccounting)
            uniAxList(dataSubjectBindList(titleInfo.companyId,rowList[index].subjectId))
            //构件添加组合详情的formitem
            let temparr:FormItemObj[] = []
            finditem.subjectAccounting.forEach((item:any)=>{
              temparr.push({label:item.accountingName,prop:(item.accountingId+'_detailId'),required:item.needAccounting?true:false,type:'select',dataList:()=>affixDetailInfo[item.accountingId] || []})
            })
            setFormItems([...temparr])
          }
          else return
        }
        setRowList(()=>[...rowList])
      }
    }
  } else {
    if (key === 'summary') return {colSpan: 3,style: {padding: 0,boxSizing:'border-box'}}
    else if (['borrowPrice','lendPrice'].includes(key)) return {colSpan: 1,style: {padding: 0,boxSizing:'border-box'}}
    else return {colSpan: 0,style: {padding: 0,boxSizing:'border-box'}}
  }
}
//保存为新模板model项
const tmplItems:FormItemObj[] = [
{label: '名称',prop: 'name'},
{label: '编码',prop: 'code'}
]

/* funcs */
const searchConfirm = async (formValus:any) => {}
//初始化tableSource
const initDataByColumns = (columns:any, initCount:number) => {
  totalSummary.total = ''
  totalSummary.borrow = 0
  totalSummary.lend = 0
  titleInfo.createDate = uniCurrentTime({})+" 00:00:00"
  titleInfo.createBy = getUserName()
  titleInfo.code = ''
  titleInfo.titleInfo = 0
  let initRowData:any = {}
  const initRow = (flatColumns:any) => {
    flatColumns.forEach((flatItem:any) => {
      if (flatItem.dataIndex && flatItem.dataIndex != 'operation') initRowData[flatItem.dataIndex] = ''
      else if (flatItem.children) initRow(flatItem.children)
    })
  }
  initRow(columns)
  console.log('initrowdata----', initRowData)
  setBlankrow(() => initRowData)
  const tempDatas:any = []
  for (let i = 0; i<initCount; i++) {
    tempDatas.push({...initRowData, key:countKey.key, borrowOrLend: 0})
    countKey.key++
  }
  setRowList(() => tempDatas)
}
//普通单元格失去焦点
const onCellBlur = (event:any, record:any, key:string) => {
  record[key] = event.target.value
  setEditCell({r:-1,c:''})
  // setIsDefaultCell(() => true)
}
//借贷单元格失去焦点
const onCashCellBlur = (value:any, record:any, index:number, key:string) => {
  let temptotal = uniNumToCh(Math.min(totalSummary.borrow, totalSummary.lend))
  totalSummary.total = temptotal
  rowList[rowList.length-1].total = temptotal
  setEditCell({r:-1,c:''})
}
//点击删除行按钮
const onDel = (index:number, record:any) => {
    if (record.borrowLend === 1) {
      totalSummary.borrow -= (Number(record.borrowPrice) || 0)
      let totalBorrowNums:any = uniSplitDigit(totalSummary.borrow)
      for (let i = 0; i < totalBorrowNums.length; i++) {
        totalSummary[borrowKeys[i]] = totalBorrowNums[i]
        rowList[rowList.length-1][borrowKeys[i]] = totalBorrowNums[i]
      }
    }
    else if (record.borrowLend === 2) {
      totalSummary.lend -= (Number(record.lendPrice) || 0)
      let totalLendNums:any = uniSplitDigit(totalSummary.lend)
      for (let i = 0; i < totalLendNums.length; i++) {
        totalSummary[lendKeys[i]] = totalLendNums[i]
        rowList[rowList.length-1][lendKeys[i]] = totalLendNums[i]
      }
    }
    if (rowList.length > 2) rowList.splice(index, 1)
    else {
      Object.keys(record).forEach((key:string) => {
        if (key !== 'key') record[key] = null
      })
      // rowList[index] = {...record, ...blankrow}
      setEditKey(() => '')
    }
    setTotalSummery(() => ({total:uniNumToCh(Math.min(totalSummary.borrow, totalSummary.lend)), borrow:totalSummary.borrow, lend:totalSummary.lend}))
    setRowList(() => ([...rowList]))
  
}
//路由跳转数据修饰
const RouterDateModify = async (routerList:any,modifyData:any, subjectlist=[]) => {
  if (!routerList || routerList.length<=0) {
    setRowList(()=>[])
    setTableLoading(()=>false)
    return
  }
  setTableLoading(()=>true)
  if (routerList && routerList.length && routerList.length > 0) {
    titleInfo.code = routerList[0].proofNo
    titleInfo.createBy = routerList[0].createBy
    titleInfo.createDate = routerList[0].createDate
    titleInfo.cancelStatus = routerList[0].cancelStatus
    titleInfo.approverName = routerList[0].approverName
    titleInfo.approverStatus = routerList[0].approverStatus
    titleInfo.approverStatusStr = uniValueToName({list:materialReviewStatus,value:routerList[0].approverStatus})
    titleInfo.payYmd = routerList[0].payYmd
    titleInfo.billYmd = routerList[0].billYmd
    titleInfo.enclosureNum = routerList[0].enclosureNum
    // modifyRows(routerList, modifyData,subjectlist)

    let subjectList
    if (accountantSubjectList.length > 0) subjectList = accountantSubjectList
    else subjectList = subjectlist
    totalSummary.total = ''
    totalSummary.borrow = 0
    totalSummary.lend = 0
    for (let j = 0; j<routerList.length; j++) {
      routerList[j].price /= 100
      if (routerList[j].borrowLend === 1) routerList[j].borrowPrice = routerList[j].price
      else routerList[j].lendPrice = routerList[j].price
      routerList[j].subjectbalance = 5
      if (modifyData && modifyData.checkStatus) uniCheckStatus(routerList[j], modifyData.checkStatus)
      let childItem:any = uniFilterItem(subjectList,'id',routerList[j].subjectId)
      if (childItem && childItem.parentId !==null) {
        let parentItem:any = uniFilterItem(subjectList,'id',childItem.parentId)
        if (parentItem) routerList[j]['subjectName'] = parentItem.name + '-' + childItem.name
      }
      // routerList[j].price /= 100
      let digNums:any = uniSplitDigit(routerList[j].price)
      routerList[j].key = j
      if (routerList[j].borrowLend === 1) {
        for (let i = 0; i < digNums.length; i++) routerList[j][borrowKeys[i]] = digNums[i]
        totalSummary.borrow += routerList[j].price
      } else if (routerList[j].borrowLend === 2) {
        for (let i = 0; i < digNums.length; i++) routerList[j][lendKeys[i]] = digNums[i]
        totalSummary.lend += routerList[j].price
      }
      initSubjectId[routerList[j].key.toString()] = routerList[j].subjectId
      // if(routerList[j].subjectId) {
      //   let balanceres = await singleSubjectRemainder({date:routerList[j].billTime,id:routerList[j].subjectId})
      //   let balancedata = balanceres.data
      //   routerList[j].subjectbalance=balancedata.balance+balancedata.changeBalance
      // }
      let detailRes = await uniAxList({...dataAffixProofList,params:{filter_EQS_proof_id:routerList[j].id}});
      if (detailRes.length>0) {
        ['accountingId','accountingName','detailId','detailName','detailNo'].forEach((key:any)=>routerList[j][key]=detailRes[0][key])
        let detailarr:any = {}
        detailarr[detailRes[0].accountingId]=detailRes[0].detailId
        affixDetailListControl[routerList[j].key.toString()] = detailarr
        initAffixDetailListControl[routerList[j].key.toString()] = uniDeepArray([detailarr])[0]
      }
    }
    totalSummary.borrow = Number(totalSummary.borrow.toFixed(2))
    totalSummary.lend = Number(totalSummary.lend.toFixed(2))
    let totalBorrowNums:any = uniSplitDigit(totalSummary.borrow)
    let totalLendNums:any = uniSplitDigit(totalSummary.lend)
    for (let i = 0; i < totalBorrowNums.length; i++) totalSummary[borrowKeys[i]] = totalBorrowNums[i]
    for (let i = 0; i < totalLendNums.length; i++) totalSummary[lendKeys[i]] = totalLendNums[i]
    totalSummary.total = uniNumToCh(Math.min(totalSummary.borrow, totalSummary.lend))

    routerList.push({...initProofItem,...totalSummary})

    setRowList(()=>[...routerList])
    setBackRowList(()=>uniDeepArray(routerList))
    
    // setRowList(()=>[...routerList, {...initProofItem}])
  } else setRowList(()=>[])
  setTableLoading(()=>false)
}
//路由跳转后加载凭证
const loadProof = async ({proofid,detail}:any) => {
  let resdata = detail
  if (proofid) {
    setTableLoading(()=>true)
    resdata = await uniAxList(dataProofDetail(proofid))
    if (!resdata) {
      return message.error('凭证未找到')
    }
  }
  //查找父级科目
  let suffixstr = ''
  resdata.detailList.forEach((item:any,index:any)=>{
    // item.borrowLendName = item.borrowLend===1?'借方':'贷方'
    if (index===0) suffixstr = suffixstr + '?id='+item.subjectId
    else suffixstr = suffixstr + '&id='+item.subjectId
  })
  let parensList = await uniAxList(dataParentSubjects(suffixstr))
  resdata.detailList.forEach((item:any,index:any)=>{
    let parentItem = parensList.find((pitem:any)=>pitem.id===item.subjectId && pitem.id!==pitem.rootId)
    if (parentItem) item.subjectName = parentItem.rootName + '-' + item.subjectName
  })
  //顶部和底部的titleinfo
  setTitleInfo({
    ...titleInfo,
    id: resdata.id,
    code: resdata.proofNo,
    createBy: resdata.createBy,
    createDate: resdata.createDate,
    cancelStatus: resdata.cancelStatus,
    approverName: resdata.approverName,
    approveStatus: resdata.approveStatus,
    approveStatusStr: uniValueToName({list:materialReviewStatus,value:resdata.approveStatus}),
    billYmd: resdata.billYmd,
    companyId: resdata.companyId,
    companyName: resdata.companyName,
    proofId: resdata.id
  })
  //表格
  totalSummary.total = ''
  totalSummary.borrow = 0
  totalSummary.lend = 0
  let routerList = resdata.detailList
  for (let j = 0; j<routerList.length; j++) {
    routerList[j].price /= 100
    if (routerList[j].borrowLend === 1) {
      routerList[j].borrowPrice = routerList[j].price
      totalSummary.borrow += routerList[j].price
    } else {
      routerList[j].lendPrice = routerList[j].price
      totalSummary.lend += routerList[j].price
    }
    routerList[j].subjectbalance = 5
    if (modifyData && modifyData.checkStatus) uniCheckStatus(routerList[j], modifyData.checkStatus)
    // initSubjectId[routerList[j].key.toString()] = routerList[j].subjectId
  }
  totalSummary.borrow = Number(totalSummary.borrow.toFixed(2))
  totalSummary.lend = Number(totalSummary.lend.toFixed(2))
  let totalBorrowNums:any = uniSplitDigit(totalSummary.borrow)
  let totalLendNums:any = uniSplitDigit(totalSummary.lend)
  for (let i = 0; i < totalBorrowNums.length; i++) totalSummary[borrowKeys[i]] = totalBorrowNums[i]
  for (let i = 0; i < totalLendNums.length; i++) totalSummary[lendKeys[i]] = totalLendNums[i]
  totalSummary.total = uniNumToCh(Math.min(totalSummary.borrow, totalSummary.lend))
  setRowList(()=>[...routerList,{...initProofItem,...totalSummary}])
  // setBackRowList(()=>uniDeepArray(routerList))
  setTableLoading(()=>false)
}

//保存模板改动
const onSave = async () => {
  setTemplateModal(()=>false)
  setTableLoading(()=>true)
  delete templateList[templateSelectIndex]['proofs'][rowList.length-1]
  const res = await updateProofTemplate(templateList[templateSelectIndex])
  if (res && res.message && (res.message === 'success' || res.message === 'SUCCESS')) {
    message.success('模板修改成功!')
    setTableLoading(()=>false)
  }
}
//重置
const reset = () => {
  initDataByColumns(columns,5);
  setSaveIdsable(()=>({isDisable:true}))
}
//保存/新建模板
const saveProof = async (formvalues?:any) => {
  if (!titleInfo.billYmd && !['newtmp','updatetmp'].includes(modalFlag)) return message.error("请选择凭证日期")
  if (!titleInfo.companyId) return message.error("请选择账所属公司")
  let companyitem:any = companyList.find((item:any)=>item.id===titleInfo.companyId)
  if (companyitem.initialize===0 && !['newtmp','updatetmp'].includes(modalFlag)) return message.error("所选公司未初始化, 不能新建凭证")
  let borrowTotal = 0
  let lendTotal = 0
  let res = null
  let accountProofDetail:any = []
  rowList.forEach((rowItem:any) => {
    let tempSubjectName = rowItem.subjectName ? rowItem.subjectName.split('-')[1] ? rowItem.subjectName.slice(rowItem.subjectName.split('-')[0].length+1)  : rowItem.subjectName : null
    let item = {...rowItem, subjectName:tempSubjectName}
    if (rowItem.subjectId && (item.borrowLend === 1 || item.borrowLend === 2)) {
      let tempindex = accountProofDetail.length + 1
      accountProofDetail.push({
        subjectId:item.subjectId,
        subjectName:item.subjectName,
        summary:item.summary,
        price:item.borrowLend === 1 ? Number((item.borrowPrice * 100).toFixed()) : Number((item.lendPrice * 100).toFixed()),
        borrowLend:item.borrowLend,
        index: tempindex,
        subjectAccountingDetailId:item.subjectAccountingDetailId,
        id:item.id
      })
    }
  })
  if (borrowTotal !== lendTotal) {
    setSaveLoading(()=>false)
    return message.error('借贷金额不一致')
  }
  let axparams = {accountProof: {...titleInfo,id:titleInfo.id,createDate:null},accountProofDetail}
  if (modalFlag==='newtmp') uniAxList(dataNewtemp({...formvalues,accountProof:{...titleInfo,detailList:accountProofDetail},companyId: getUserInfoKey('groupItemId')}))
  else if (modalFlag==='updatetmp') uniAxList(dataUpdatetemp({...formvalues,accountProof:{...titleInfo,detailList:accountProofDetail},companyId: curTmplate.companyId,id:curTmplate.id}))
  else if (titleInfo.id) {
    res = await axProofUpdate({id:titleInfo.id,billYmd:titleInfo.billYmd,accountProofDetail})
    if (res) message.success('操作成功')
    loadProof({proofid:res.data.id})
  } else {
    res = await createProof(axparams)
    if(res && res.message && res.message.toLowerCase() === 'success'){
      let backprooflist = res.data
      message.success('操作成功')
      setSaveLoading(()=>false)
      // titleInfo.payYmd = uniCurrentTime({})
      // titleInfo.companyId = null
      // titleInfo.companyName = null
      rowList.forEach((rowitem:any)=>{
        if (rowitem.affixdetail && rowitem.affixdetail.length>0) {
          let findBackItem = backprooflist.find((backitem:any)=>backitem.borrowLend===rowitem.borrowLend && backitem.summary===rowitem.summary && backitem.companyId===rowitem.companyId && backitem.subjectId===rowitem.subjectId && backitem.price===rowitem.price*100)
          if (findBackItem) uniAxList({...dataAddAffixProof,params:{details:rowitem.affixdetail,proofId:findBackItem.id}})
        }
      })
      reset()
    }
  }
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  if (modalFlag==='accounting') {
    let temparr:any = []
    cursubjectItem.subjectAccounting.forEach((item:any)=>{
      let detailid = formValues[item.accountingId+'_detailId']
      let finditem
      if (detailid) finditem = affixDetailInfo[item.accountingId].find((detailitem:any)=>detailitem.id === detailid)
      temparr.push({accountingId:item.accountingId,accountingName:item.accountingName,detailId:detailid,detailName:finditem?.name,detailNo:finditem?.no})
    })
    uniAxList(dataBindAffixdetails({companyId:titleInfo.companyId,subjectId:cursubjectItem.id,accountingDetail:temparr}))
  }
  else if (modalFlag==='newtmp' || modalFlag==='updatetmp') saveProof(formValues)
  else if (modalFlag==='choosetmp') {
    if (mselectedRowKeys.length <= 0) return message.error('请选择模板')
    let finditem = templateList.find((item:any)=>item.id===mselectedRowKeys[0])
    topformRef.current?.setFieldsValue({companyId: finditem.accountProof.companyId})
    let tempcompany = companyList.find((item:any)=>item.id===finditem.accountProof.companyId)
    setModelCompanyItem(tempcompany)
    loadProof({detail:finditem.accountProof})
    setCurTmplate(finditem)
    setShowModal(false)
    setFromTmpl(true)
  } else if (modalFlag==='review') {
    topformRef.current?.setFieldsValue({approveStatusStr:'通过审核',approverName:getUserInfoKey('name')})
    uniAxList(dataConfirm(formValues))
  }
}
//点击打印
const handleprint = useReactToPrint({contentRef: printref})

/* 接口参数 */
const uniCompanyData:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList,
  awaitFunc: (predata:any) => {
    let mystate:any = state
    if (mystate && mystate.detail) {
      let finditem:any = predata.find((item:any)=>item.id===mystate.detail.companyId)
      setModalEndym(finditem.endYm)
    }
  }
}
//记账凭证详情
const dataProofDetail = (proofid:any):AxDataform => ({
  params: {id:proofid},
  axApi: axProofdetail,
})
//反审核
const dataUnConfirm = ():AxDataform => ({
  params: {ids: [titleInfo.id]},
  axApi: unReviewProof,
  awaitFunc:()=>{
    loadProof({proofid:titleInfo.id})
    topformRef.current?.setFieldsValue({approveStatusStr:'待审核',approverName:null})
  }
})
//审核
const dataConfirm = (formvalus:any):AxDataform => ({
  params: {...formvalus,ids: [titleInfo.id]},
  axApi: reviewProof,
  setModalLoading,
  setShowModal,
  awaitFunc:()=>{
    loadProof({proofid:titleInfo.id})
  }
})
//删除凭证
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteProof,
  successTip: true
})
//公司会计科目列表
const dataSubjectList = (companyId:any):AxDataform => ({
  params: {page:1, size:999,companyId,showProof:true},
  axApi: axSubjectList,
  itemModify: {modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
  setList: setAccountantSubjectList
})
//模板列表
const dataTempList:AxDataform = {
  axApi: getProofTemplates,
  setList: setTemplateList,
  setTableLoading:setModaltableLoading
}
const uniGetProofData:any = {
  modifyFunc: RouterDateModify,
  modifyData: modifyData,
  axApi: getProofList,
  setList: setRowList,
  setTableLoading,
  finishLoading:false
}
//上一张下一张等
const dataProofList = (type:string):AxDataform => {
  let tempparams
  let tempapi = type==='monthf'?axProofMonthf:type==='pre'?axProofPre:type==='next'?axProofNext:axProofMonthe
  if (type==='monthf' || type==='monthe') tempparams = {ym:titleInfo.billYmd.slice(0,7),companyId:titleInfo.companyId}
  else if (type==='pre' || type==='next') tempparams = {id:titleInfo.proofId}
  return {
    params:tempparams,
    axApi: tempapi,
    setTableLoading,
    finishLoading:false,
    awaitFunc: (predata:any)=>{
      console.log('predata-------',predata)
      if (!predata) {
        setRowList(()=>[])
        setTableLoading(()=>false)
        return
      }
      loadProof({detail:predata})
      // RouterDateModify(predata[0].proofs, modifyData,[])
    }
  }
}
//获取科目详情组合
const dataSubjectBindList = (companyId:any,subjectId:any):AxDataform => {
  return {
    params:{companyId,subjectId},
    axApi: axSubjectBindList,
    setList: setSubjectbindList
  }
}
//科目绑定辅助核算详情
const dataBindAffixdetails = (params:any):AxDataform => ({
  params,
  axApi: axSubjectBindAffixdetails,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any)=>{
   uniAxList(dataSubjectBindList(params.companyId,params.subjectId))
   setCurbindKey(predata.id)
  }
})
//辅助核算详情列表
const dataAffixDetailList = (assistId:any,companyId:any):AxDataform => ({
  params: {accountingId: assistId,companyId},
  axApi: axAffixSubjectDetail,
  awaitFunc: (predata:any)=>{
    affixDetailInfo[assistId] = predata
    setAffixDetailInfo({...affixDetailInfo})
  }
})
//记账凭证关联会计科目id
const dataAddAffixProof:any = {
  axApi: axCreateAffixProof,
  setModalLoading:setSaveLoading
}
//辅助核算和记账凭证关联列表
const dataAffixProofList:any = {
  axApi: axAffixProofList,
}
//查找父级科目
const dataParentSubjects = (idstr:any):AxDataform => ({
  params: idstr,
  axApi: getParentSubjects,
})
//新建模板
const dataNewtemp = (params:any):AxDataform => ({
  params,
  axApi: createProofTemplate,
  setModalLoading,
  setShowModal,
  successTip:true
})
//修改模板
const dataUpdatetemp = (params:any):AxDataform => ({
  params,
  axApi: updateProofTemplate,
  setModalLoading,
  setShowModal,
  successTip:true
})
//删除模板
const dataDeltmprow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteProofTemplate,
  successTip: true,
  awaitFunc: ()=>{
    uniAxList(dataTempList)
    setMselectedRowKeys([])
  }
})

//获取
/* mounted */
React.useEffect(() => {
  uniAxList(uniCompanyData)
  if(!state) {
    // uniAxList(uniAsData)
    initDataByColumns(columns, 5)
  }
  setLoadFlag(()=>true)
}, [])
/* useEffct */
React.useEffect(() => {
  if (state && location.pathname===pathname) {
    navigateFlag.value = true
    let mystate:any = state
    let {detail} = mystate
    if (companyList.length > 0) {
      let finditem:any = companyList.find((item:any)=>item.id===detail.companyId)
      setModalEndym(finditem.endYm)
    }
    uniAxList(dataSubjectList(detail.companyId))
    topformRef.current?.setFieldsValue({code: detail.proofNo,createDate:moment(detail.createDate),companyId: detail.companyId,billYmd: moment(detail.billYmd),createBy: detail.createBy,approverName:detail.approverName,approveStatus:detail.approveStatus,approveStatusStr:uniValueToName({list:materialReviewStatus,value:detail.approveStatus})})
    loadProof({detail})
  }
}, [location])

return (
  <>
    <TopSearch
      ref={searchRef}
      topActions={topActions}
      searchList={[]}
      hasButton={false}
      searchConfirm={searchConfirm}
    />
    {/* {titleInfo.cancelStatus === 1 ? <img className={recordvouchercss.cancel} src={require('../../materials/img/canceled.png')} alt="" /> : null} */}
    <h2 style={{textAlign:'center', fontWeight:'bold'}}>记账凭证</h2>
    <TopSearch
      ref={topformRef}
      searchList={topformItems}
      searchConfirm={()=>{}}
      initialValues={{createDate:moment(), createBy:getUserName()}}
      hasButton={false}
    />
    <SuperTable
      rowKey='key'
      bordered={true}
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      onRow={(record:any, index:any) => {
        return {
          height: '60px',
          style: index === rowList.length-1 ? {backgroundColor:'#87ceeb',fontWeight:'bold'}:{backgroundColor:(index%2===0?'#fff':'rgb(240, 231, 214)')},
          onClick: (event:any) => {}, // 点击行
          onDoubleClick: (event:any) => {},
          onContextMenu: (event:any) => {},
          onMouseEnter: (event:any) => {
            if (index !== rowList.length-1) setHoverIndex(()=>index)
          }, // 鼠标移入行
          onMouseLeave: (event:any) => {setHoverIndex(()=>-1)},
        } as any;
      }}
    />
    <FormDialog
      ref={modalRef}
      title={modalFlag==='accounting'?'新增组合详情':modalFlag==='newtmp'?'新建模板':modalFlag==='choosetmp'?'选择模板':modalFlag==='updatetmp'?'修改模板':modalFlag==='review'?'审核':''}
      show={showModal}
      formItems={modalFlag==='accounting'?formItems:modalFlag==='choosetmp'?[]:modalFlag==='review'?reviewItems:tmplItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      hasConfirm={true}
      showCustom={modalFlag==='choosetmp'}
      cancel={()=>{
        setMselectedRowKeys([])
        setMselectedRows([])
        setFormInitials({})
        setShowModal(()=>false)
        setModalFlag('')
      }}
      confirm={modalConfirm}
    >
      <span></span>
      <SuperTable
        bordered={true}
        columns={tmpcolumns}
        rowList={templateList}
        tableLoading={modaltableLoading}
        rowSelection={{type: 'radio',selectedRowKeys:mselectedRowKeys,setSelectedRowKeys:setMselectedRowKeys,setSelectedRows:setMselectedRows}}
      />
    </FormDialog>
    <Modal
      width={1000}
      style={{height:'400px'}}
      maskClosable={false}
      title={'凭证打印'}
      open={showPrintmodal}
      onOk={handleprint}
      okText="打印预览"
      onCancel={()=>{setShowPrintmodal(false)}}
      afterClose={()=>{setShowPrintmodal(false)}}
      destroyOnClose
    >
      <div id="recordvoucherPrint" ref={printref} style={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
      <CpTprint title="记账凭证" printList={rowList.slice(0,-1)} columns={columns.slice(2)} hasIndex={false} hasSum={true} sumTitlespan={3} capKey='borrowPrice' sumProps={['borrowPrice','lendPrice']}>
        <div style={{width: '100%',display: 'flex',justifyContent: 'space-between'}}>
          <div>凭证编号: {titleInfo.code}</div>
          <div>所属公司: {titleInfo.invoiceTitle || titleInfo.companyName}</div>
          <div>账单日期: {titleInfo.billYmd}</div>
        </div>
        <div style={{width: '100%',display: 'flex',justifyContent: 'space-between'}}>
          <div>制单人: {titleInfo.createBy}</div>
          <div>状态: {titleInfo.approveStatusStr}</div>
          <div>审核人: {titleInfo.approverName}</div>
        </div>
      </CpTprint>
      </div>
    </Modal>
  </>
)
}
